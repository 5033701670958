<template>
  <div class="product-card">
    <SfLink
      v-if="product.productExist"
      :link="product.link"
      class="product-card__img-link"
    >
      <slot name="image">
        <SfImage
          class="product-card__img"
          :src="product.sku | productImagePath(85, 85)"
          :alt="product.title"
          width="85"
          height="85"
          lazy
        />
      </slot>
    </SfLink>
    <div
      v-else
      class="product-card__img-link"
    >
      <SfImage
        class="product-card__img"
        :src="product.sku | productImagePath(85, 85)"
        :alt="product.title"
        width="85"
        height="85"
        lazy
      />
    </div>
    <div class="product-card__desc">
      <SfLink
        v-if="product.productExist"
        :link="product.link"
      >
        <span class="product-card__title">
          {{ product.title }}
        </span>
      </SfLink>
      <div v-else>
        <span class="product-card__title">
          {{ product.title }}
        </span>
      </div>
      <div class="product-card__quantity">
        {{ getProductQuantityText(product.quantityText) }}
      </div>
      <div class="product-card__action">
        <div
          v-if="product.qty"
          class="card-action__quantity"
        >
          {{ product.qty }} {{ measure }}
        </div>
        <span
          v-if="priceIncreased || priceReduced"
          class="price-change"
          :class="{'increased': priceIncreased, 'reduced': priceReduced }"
        />
        <div class="sf-price">
          <span class="sf-price__regular">
            {{ product.total }}
            <span class="price-currency">
              грн
            </span>
          </span>
        </div>
        <NoSSR>
          <MProductAddToCart
            v-if="isProductAvailable"
            :product="product"
            :stock="product.stock"
            is-icon-button
            :is-quantity-available="false"
            class="product-card__basket"
          />
        </NoSSR>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SfImage,
  SfLink
} from '@storefront-ui/vue';
import { mapGetters } from 'vuex';
import { isGift } from '../../helpers/gift';
import { getProductType } from 'theme/helpers/product';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'MAccountOrderCard',
  components: {
    SfImage,
    SfLink,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null,
    NoSSR
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    image: {
      type: [Array, Object, String],
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      productAttributeLabel: 'product-extension/getProductAttributeLabel',
      isNewPost: 'shipping-module/isCurrentNewPost'
    }),
    isProductAvailable () {
      if (this.isNewPost && !this.product.forNewPost) return false

      return this.product.productExist && this.product.stock?.is_in_stock && !isGift(this.product)
    },
    currentPrice () {
      return this.product.currentPrice?.special ? this.product.currentPrice?.special : this.product.currentPrice?.regular
    },
    priceIncreased () {
      return parseFloat(this.currentPrice) > parseFloat(this.product.price?.regular)
    },
    priceReduced () {
      return parseFloat(this.currentPrice) < parseFloat(this.product.price?.regular)
    },
    measureKg () {
      const { type, packing } = getProductType(this.product)

      return type === 'kg' && packing
    },
    measure () {
      return this.measureKg
        ? this.$t('kg')
        : this.$t('pc');
    }
  },
  methods: {
    getProductQuantityText (text = '') {
      return text.slice(3)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";
@import '~theme/css/fonts';

.product-card {
  display: flex;
  margin: 0;
  background: var(--white);
  padding: var(--spacer-15) var(--spacer-20);

  @media (max-width: $mobile-max) {
    padding: var(--spacer-10);
  }

  &__img-link {
    display: flex;
    width: var(--spacer-85);
  }

  &__desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: var(--spacer-11);

    .sf-link {
      display: flex;
    }
  }

  &__title {
    font-size: var(--font-size-13);
    line-height: var(--font-size-16);
    margin: 0 0 var(--spacer-5) 0;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-line-clamp: 2;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }
  &__quantity {
    font-family: var(--font-family-inter);
    font-size: var(--font-size-12);
    color: var(--dark-gray);
    margin-top: var(--spacer-2);
    margin-right: var(--spacer-24);
  }
  &__action {
    display: flex;
    align-items: center;
    margin-top: var(--spacer-5);

    .card-action__quantity {
      font-family: var(--font-family-inter);
      flex-shrink: 0;
      font-size: var(--font-size-13);
      color: var(--black);
      margin-top: var(--spacer-2);
      margin-right: var(--spacer-24);
    }

    .price-change {
      margin-right: var(--spacer-6);
      @include font-icon(var(--icon-arrow-back));

      &:before {
        font-size: var(--font-size-16);
      }

      &.reduced {
        transform: rotate(-90deg);
        &:before {
          color: #0CBD2A;
        }
      }

      &.increased {
        transform: rotate(90deg);
        &:before {
          color: #FB1A34;
        }
      }
    }

    ::v-deep .sf-price {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: start;

      .sf-price__regular {
        font-size: var(--font-size-18);
        line-height: var(--font-size-20 );
        font-weight: var(--font-medium);

        .price-currency {
          font-size: var(--font-size-14);
          font-weight: var(--font-medium);
        }
      }
    }
  }

  &__basket {
    margin: 0 0 0 var(--spacer-30);
  }

  ::v-deep .a-add-to-cart {
    width: var(--spacer-58);
    height: var(--spacer-40);
  }

  ::v-deep .sf-add-to-cart {
    font-size: 0;
  }
}
</style>
